<template>
  <ValidationObserver ref="form">
    <div>
      <div class="modal-body" id="modalBody" style="padding-top: 0px">
        <div class="mt-1">
          <b-row >
            <b-col cols="12" md="6">
              <span class="title-tag">ACCOUNT</span>
              <div class="border border-primary rounded">
                <p
                  class="text-primary d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{ clientData.account || "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <span class="title-tag">CLIENT NAME</span>
              <div class="border border-primary rounded">
                <p
                  class="text-primary d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{ clientData.client_name || "-" }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="col-lg-12 mt-1 px-0">
          <table class="table mb-0 mt-2">
            <thead class="bg-transparent">
              <tr>
                <th class="border-0 p-0"></th>
                <th class="text-center align-middle flex-row border-0 p-0" v-if="clientData.sumtr">
                  <img
                    :src="require('@/assets/images/icons/transunion-logo.png')"
                    width="90"
                  />
                </th>
                <th v-if="clientData.sumex" class="text-center align-middle flex-row border-0 p-0">
                  <img
                    :src="require('@/assets/images/icons/experian-logo.png')"
                    width="90"
                  />
                </th>
                <th class="text-center align-middle flex-row border-0 p-1" v-if="clientData.sumeq">
                  <img
                    :src="require('@/assets/images/icons/equifax-logo.png')"
                    width="90"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="border-bottom">
                <td>Case Number</td>
                <td v-if="clientData.sumtr" class="text-center">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      class="input-form text-center bg-transparent form-control"
                      type="text"
                      v-model="case_number.cn_tu"

                      :disabled="validedit != 0"
                      :state="errors[0] ? false : null"
                    />
                  </ValidationProvider>
                </td>
                <td v-if="clientData.sumex" class="text-center">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      class="input-form text-center bg-transparent form-control"
                      type="text"
                      v-model="case_number.cn_exp"

                      :disabled="validedit != 0"
                      :state="errors[0] ? false : null"
                    />
                  </ValidationProvider>
                </td>
                <td v-if="clientData.sumeq" class="text-center">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      class="input-form text-center bg-transparent form-control"
                      type="text"
                      v-model="case_number.cn_eq"

                      :disabled="validedit != 0"
                      :state="errors[0] ? false : null"
                    />
                  </ValidationProvider>
                </td>
              </tr>
            </tbody>
          </table>

          <b-row class="mt-2 text-center">
            <b-col md="12">
              <b-row class="class-inline">
                <b-col
                  md="2"
                  class="class-campo-icon add-class-campo-icon"
                  style="border-radius: 10px 10px 0px 0px"
                >
                  <span>OBSERVATION</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <ValidationProvider
                name="description"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group mt-1">
                  <b-form-textarea
                    v-model="case_number.description"
                    class="b-form-style"
                    style="border-radius: 0px 10px 10px 10px; height: 20%"
                    :disabled="validedit != 0"
                    no-resize
                    :state="errors[0] ? false : null"
                  ></b-form-textarea>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="mt-1 text-center">
            <b-col class="text-center">
              <h6 v-if="!isThereBureaus" class="text-danger mb-1">
                Please, add some bureaus before processing this round.
              </h6>
              <button
                class="btn btn-success mr-1"
                v-if="validedit == 0"
                @click="setDirectBureau()"
                style="background: #147ca6"
              >
                SAVE
              </button>

              <button
                :disabled="!isThereBureaus"
                class="btn btn-success"
                v-if="validedit == 0"
                @click="setDirectComplete()"
                style="background: #00cc00"
              >
                SAVE AND COMPLETE
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import DirectRoundsServices from "@/views/specialist-digital/views/directs-round/directs-round.service";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    clientData: {
      type: Object,
    },
    validEdit: {
      type: Number,
    },
  },
  data() {
    return {
      validedit: this.validEdit,
      case_number: {
        round_id: this.clientData.id,
        st_tu: null,
        st_exp: null,
        st_eq: null,
        cn_tu: null,
        cn_exp: null,
        cn_eq: null,
        description: null,
      },
    };
  },
  created() {
    this.getDirectComplete();
  },
  methods: {
    async setDirectBureau() {
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;
      try {
        this.addPreloader();
        const setBureau = await DirectRoundsServices.setDataDirect(
          this.case_number
        );
        if (setBureau.status == 200) {
          this.showSuccessSwal();
          this.$emit("close")
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error in setDirectBureau", error);
      }
    },
    async setDirectComplete() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;
      try {
        this.addPreloader();
        const setBureau = await DirectRoundsServices.setDataDirect(
          this.case_number
        );
        if (setBureau.status == 200) {
          const processLetters = await DirectRoundsServices.processRoundLetters(
            {
              id: this.clientData.id,
              user: this.currentUser.user_id,
              type: 7,
              obs: this.case_number["description"],
            }
          );
          if (processLetters.status == 200) {
            this.UPDATE_SIDEBAR_DIRECT_COUNTER({
              routeName: "specialistdigital-directs-round",
              tag:
                this.pendingDirectRounds > 99
                  ? "+99"
                  : this.pendingDirectRounds - 1,
            });
            this.UPDATE_TAB_DIRECT_COUNTER({
              pending: this.pendingDirectRounds - 1,
            });
            this.showSuccessSwal();
            this.updateTable();
          }
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal("Error in setDirectComplete");
        this.removePreloader();
      }
    },
    async getDirectComplete() {
      try {
        this.addPreloader();
        const response = await DirectRoundsServices.getDataDirect({
          idround: this.clientData.id,
        });
        if (response.status == 200) {
          if (response.data.length > 0) {
            this.case_number["description"] = response.data[0].observation;
            this.case_number["cn_tu"] = response.data[0].case_number_tu;
            this.case_number["cn_exp"] = response.data[0].case_number_exp;
            this.case_number["cn_eq"] = response.data[0].case_number_eq;
          }
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error in getDirectComplete", error);
      }
    },
    updateTable() {
      this.$emit("update");
      // this.$emit("close");
    },
    ...mapMutations({
      UPDATE_SIDEBAR_DIRECT_COUNTER:
        "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    ...mapActions({
      UPDATE_TAB_DIRECT_COUNTER:
        "SpecialistsDigitalDirectRoundsStore/A_SET_COUNTER_PENDING_DIRECT_ROUNDS",
    }),
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      pendingDirectRounds:
        "SpecialistsDigitalDirectRoundsStore/G_COUNTER_PENDING_DIRECT_ROUNDS",
    }),
    isThereBureaus() {
      // Checks if some bureaus is added to this round
      return (
        !!this.clientData.sumtr ||
        !!this.clientData.sumex ||
        !!this.clientData.sumeq
      );
    },
  },
};
</script>

<style></style>
