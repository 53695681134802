var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('div',[_c('div',{staticClass:"modal-body",staticStyle:{"padding-top":"0px"},attrs:{"id":"modalBody"}},[_c('div',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"title-tag"},[_vm._v("ACCOUNT")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.clientData.account || "-")+" ")])])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"title-tag"},[_vm._v("CLIENT NAME")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.clientData.client_name || "-")+" ")])])])],1)],1),_c('div',{staticClass:"col-lg-12 mt-1 px-0"},[_c('table',{staticClass:"table mb-0 mt-2"},[_c('thead',{staticClass:"bg-transparent"},[_c('tr',[_c('th',{staticClass:"border-0 p-0"}),(_vm.clientData.sumtr)?_c('th',{staticClass:"text-center align-middle flex-row border-0 p-0"},[_c('img',{attrs:{"src":require('@/assets/images/icons/transunion-logo.png'),"width":"90"}})]):_vm._e(),(_vm.clientData.sumex)?_c('th',{staticClass:"text-center align-middle flex-row border-0 p-0"},[_c('img',{attrs:{"src":require('@/assets/images/icons/experian-logo.png'),"width":"90"}})]):_vm._e(),(_vm.clientData.sumeq)?_c('th',{staticClass:"text-center align-middle flex-row border-0 p-1"},[_c('img',{attrs:{"src":require('@/assets/images/icons/equifax-logo.png'),"width":"90"}})]):_vm._e()])]),_c('tbody',[_c('tr',{staticClass:"border-bottom"},[_c('td',[_vm._v("Case Number")]),(_vm.clientData.sumtr)?_c('td',{staticClass:"text-center"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-form text-center bg-transparent form-control",attrs:{"type":"text","disabled":_vm.validedit != 0,"state":errors[0] ? false : null},model:{value:(_vm.case_number.cn_tu),callback:function ($$v) {_vm.$set(_vm.case_number, "cn_tu", $$v)},expression:"case_number.cn_tu"}})]}}],null,false,197063391)})],1):_vm._e(),(_vm.clientData.sumex)?_c('td',{staticClass:"text-center"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-form text-center bg-transparent form-control",attrs:{"type":"text","disabled":_vm.validedit != 0,"state":errors[0] ? false : null},model:{value:(_vm.case_number.cn_exp),callback:function ($$v) {_vm.$set(_vm.case_number, "cn_exp", $$v)},expression:"case_number.cn_exp"}})]}}],null,false,3161551059)})],1):_vm._e(),(_vm.clientData.sumeq)?_c('td',{staticClass:"text-center"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-form text-center bg-transparent form-control",attrs:{"type":"text","disabled":_vm.validedit != 0,"state":errors[0] ? false : null},model:{value:(_vm.case_number.cn_eq),callback:function ($$v) {_vm.$set(_vm.case_number, "cn_eq", $$v)},expression:"case_number.cn_eq"}})]}}],null,false,3546055146)})],1):_vm._e()])])]),_c('b-row',{staticClass:"mt-2 text-center"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"class-inline"},[_c('b-col',{staticClass:"class-campo-icon add-class-campo-icon",staticStyle:{"border-radius":"10px 10px 0px 0px"},attrs:{"md":"2"}},[_c('span',[_vm._v("OBSERVATION")])])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mt-1"},[_c('b-form-textarea',{staticClass:"b-form-style",staticStyle:{"border-radius":"0px 10px 10px 10px","height":"20%"},attrs:{"disabled":_vm.validedit != 0,"no-resize":"","state":errors[0] ? false : null},model:{value:(_vm.case_number.description),callback:function ($$v) {_vm.$set(_vm.case_number, "description", $$v)},expression:"case_number.description"}})],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1 text-center"},[_c('b-col',{staticClass:"text-center"},[(!_vm.isThereBureaus)?_c('h6',{staticClass:"text-danger mb-1"},[_vm._v(" Please, add some bureaus before processing this round. ")]):_vm._e(),(_vm.validedit == 0)?_c('button',{staticClass:"btn btn-success mr-1",staticStyle:{"background":"#147ca6"},on:{"click":function($event){return _vm.setDirectBureau()}}},[_vm._v(" SAVE ")]):_vm._e(),(_vm.validedit == 0)?_c('button',{staticClass:"btn btn-success",staticStyle:{"background":"#00cc00"},attrs:{"disabled":!_vm.isThereBureaus},on:{"click":function($event){return _vm.setDirectComplete()}}},[_vm._v(" SAVE AND COMPLETE ")]):_vm._e()])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }