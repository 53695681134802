var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('filter-slot',{staticClass:"border-top-primary border-3 border-table-radius p-0 m-0",attrs:{"filter":_vm.filtersC,"filter-principal":_vm.filterPrincipal,"totalRows":_vm.totalRows,"paginate":_vm.paginate,"startPage":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['directsRoundListTable'].refresh()},"reset-all-filters":_vm.refreshTable}},[_c('b-table',{ref:"directsRoundListTable",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.myProvider,"fields":_vm.filteredFields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"100vh"},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2",staticStyle:{"color":"#dfb15a !important"}},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(item){return [_c('tr',[_c('td',{staticClass:"border-0 p-0"},[_c('status-account',{attrs:{"account":item.item,"text":false}})],1),_c('td',{staticClass:"border-0 p-0"},[(_vm.moduleId === 11 || _vm.moduleId == 25)?_c('div',[_c('a',{attrs:{"href":(_vm.$route.matched[0].meta.module == 11
                      ? '/specialists/'
                      : '/specialistdigital/') +
                    'clients/account/' +
                    item.item.client_account_id}},[_vm._v(" "+_vm._s(item.item.client_name)+" ")])]):_c('div',[([1, 2, 14, 17].includes(_vm.currentUser.role_id))?_c('router-link',{class:_vm.isDarkSkin ? 'text-warning' : 'text-primary',attrs:{"to":{
                    name: 'ce-customer-service-dashboard',
                    params: {
                      idClient: item.item.client_account_id,
                    },
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(item.item.client_name)+" ")]):_c('b-button',{staticClass:"py-0 pl-0",class:_vm.isDarkSkin ? 'text-warning' : 'text-primary',attrs:{"variant":"outline"},on:{"click":function($event){return _vm.validateAccess(item.item)}}},[_vm._v(" "+_vm._s(item.item.client_name)+" ")])],1),_c('div',[_vm._v(_vm._s(item.item.mobile))]),_c('div',[_vm._v(_vm._s(item.item.account))])])])]}},{key:"cell(program)",fn:function(item){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.item.program)+" ")])]}},{key:"cell(created_by)",fn:function(item){return [_c('div',[_vm._v(_vm._s(item.item.created_by))]),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.item.created_at))+" ")]}},{key:"cell(advisor)",fn:function(item){return [_c('div',[_vm._v(_vm._s(item.item.advisor_name))])]}},{key:"cell(cr)",fn:function(item){return [_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"gap":"10px"}},[(_vm.isSpecialist)?_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
                  name:
                    _vm.moduleId == 11
                      ? 'specialist-report-lead'
                      : _vm.moduleId == 25
                      ? 'specialistdigital-report-lead'
                      : '',
                  params: {
                    idfile: item.item.score_id || item.item.last_score,
                    idlead: item.item.lead_id,
                    accountId: item.item.client_account_id,
                    modul: _vm.moduleId,
                    isDigital: true,
                  },
                }}},[_c('feather-icon',{class:_vm.isDarkSkin ? 'text-primary' : 'text-dark',attrs:{"size":"20","icon":"FileTextIcon"}})],1)],1):_vm._e(),_c('div',{staticStyle:{"width":"20px"}},[(item.item.route_file != null)?_c('a',{staticClass:"pr-2",attrs:{"target":"_blank","href":item.item.route_file}},[_c('b-img',{attrs:{"src":require('@/assets/images/icons/pdf.png'),"width":"20"}})],1):_vm._e()])])]}},{key:"cell(round)",fn:function(item){return [_c('div',{staticClass:"text-center text-primary",class:{ 'cursor-pointer': _vm.isSpecialist },staticStyle:{"margin-top":"5px"}},[_c('div',{on:{"click":function($event){_vm.isSpecialist && _vm.openModalAddRound(item.item)}}},[_vm._v(" "+_vm._s(item.item.title.split(" ")[0])+" ")])])]}},{key:"cell(type_send)",fn:function(ref){
                var item = ref.item;
return [_c('center',{staticStyle:{"margin-top":"5px"}},[_c('b-badge',{attrs:{"variant":item.type_send === 'PHONE' ? 'light-info' : 'light-primary'}},[_vm._v(" "+_vm._s(item.type_send)+" ")])],1)]}},{key:"cell(tu)",fn:function(item){return [_c('div',[_vm._v(" "+_vm._s(item.item.sumtr || "-")+" ")])]}},{key:"cell(ex)",fn:function(item){return [_c('div',[_vm._v(" "+_vm._s(item.item.sumex || "-")+" ")])]}},{key:"cell(eq)",fn:function(item){return [_c('div',[_vm._v(" "+_vm._s(item.item.sumeq || "-")+" ")])]}},{key:"cell(sent_by)",fn:function(item){return [_c('div',[_vm._v(" "+_vm._s(item.item.sent_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.item.date_send))+" ")])]}},{key:"cell(action)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-around gap-1"},[_c('FeatherIcon',{staticClass:"text-warning cursor-pointer",attrs:{"icon":"EditIcon","size":"20"},on:{"click":function($event){return _vm.openModalEditRoundLetter(
                  item.id,
                  item.client_account_id,
                  item.account,
                  item.client_name
                )}}}),_c('b-button',{attrs:{"size":"sm","variant":"success","disabled":item.sumeq > 0 || item.sumex > 0 || item.sumtr > 0
                  ? false
                  : true},on:{"click":function($event){return _vm.openModalProcessDirect(item, 0)}}},[_vm._v(" Done ")]),(
                (_vm.isSpecialist && _vm.isCeo) ||
                _vm.isSupervisor ||
                _vm.isChief ||
                _vm.isAdvisor
              )?_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"TrashIcon","size":"20","variant":"danger"},on:{"click":function($event){return _vm.deleteRoundLetter(item.id)}}}):_vm._e()],1)]}},{key:"cell(tracking)",fn:function(item){return [_c('div',{staticClass:"text-center"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Tracking'),expression:"'Tracking'",modifiers:{"hover":true,"right":true}}],staticClass:"text-info cursor-pointer",attrs:{"icon":"ListIcon","size":"20"},on:{"click":function($event){return _vm.openModalTracking(item.item)}}})],1)]}}])})],1)],1),(_vm.modalTrackingState)?_c('modal-tracking',{attrs:{"dataClient":_vm.dataClient},on:{"hideModal":function($event){_vm.modalTrackingState = false}}}):_vm._e(),(_vm.modalProcessDirect)?_c('modal-process-direct',{attrs:{"dataClient":_vm.dataClient,"direct":_vm.directType,"validedit":_vm.validEdit},on:{"hideModal":function($event){_vm.modalProcessDirect = false},"update":_vm.refreshTable}}):_vm._e(),(_vm.modalEditRoundLetterState)?_c('modal-edit-round-letter',{attrs:{"idround":_vm.ncrId,"idaccount":_vm.clientAccountId,"account":_vm.accountId,"nameClient":_vm.nameClient,"direct":true},on:{"updateTable":_vm.refreshTable,"hideModal":function($event){_vm.modalEditRoundLetterState = false}}}):_vm._e(),(_vm.modalAddRoundLetterState)?_c('add-round-modal',{attrs:{"data-round":_vm.itemClient,"validate-modal":_vm.validateModal},on:{"close":function($event){return _vm.closeRoundModal()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }