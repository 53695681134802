<template>
  <div>
    <b-container class="p-0" fluid>
      <!-- startPage -->
      <filter-slot
        class="border-top-primary border-3 border-table-radius p-0 m-0"
        :filter="filtersC"
        :filter-principal="filterPrincipal"
        :totalRows="totalRows"
        :paginate="paginate"
        :startPage="startPage"
        :to-page="toPage"
        @reload="$refs['directsRoundListTable'].refresh()"
        @reset-all-filters="refreshTable"
      >
        <!--:::ini table::::-->
        <b-table
          small
          slot="table"
          no-provider-filtering
          ref="directsRoundListTable"
          :items="myProvider"
          :fields="filteredFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="100vh"
        >
          <template #table-busy>
            <div class="text-center my-2" style="color: #dfb15a !important">
              <b-spinner class="align-middle" type="grow"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(client_name)="item">
            <tr>
              <td class="border-0 p-0">
                <status-account
                  :account="item.item"
                  :text="false"
                ></status-account>
              </td>

              <td class="border-0 p-0">
                <div v-if="moduleId === 11 || moduleId == 25">
                  <a
                    :href="
                      ($route.matched[0].meta.module == 11
                        ? '/specialists/'
                        : '/specialistdigital/') +
                      'clients/account/' +
                      item.item.client_account_id
                    "
                  >
                    {{ item.item.client_name }}
                  </a>
                </div>
                <div v-else>
                  <router-link
                    v-if="[1, 2, 14, 17].includes(currentUser.role_id)"
                    :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                    :to="{
                      name: 'ce-customer-service-dashboard',
                      params: {
                        idClient: item.item.client_account_id,
                      },
                    }"
                    target="_blank"
                  >
                    {{ item.item.client_name }}
                  </router-link>
                  <b-button
                    variant="outline"
                    class="py-0 pl-0"
                    :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                    v-else
                    @click="validateAccess(item.item)"
                  >
                    {{ item.item.client_name }}
                  </b-button>
                </div>
                <div>{{ item.item.mobile }}</div>
                <div>{{ item.item.account }}</div>
              </td>
            </tr>
          </template>
          <template #cell(program)="item">
            <div class="text-center">
              {{ item.item.program }}
            </div>
          </template>
          <template #cell(created_by)="item">
            <div>{{ item.item.created_by }}</div>
            {{ item.item.created_at | myGlobalDay }}
          </template>
          <template #cell(advisor)="item">
            <div>{{ item.item.advisor_name }}</div>
          </template>

          <template #cell(cr)="item">
            <div class="d-flex justify-content-center" style="gap: 10px">
              <div class="text-center" v-if="isSpecialist">
                <router-link
                  :to="{
                    name:
                      moduleId == 11
                        ? 'specialist-report-lead'
                        : moduleId == 25
                        ? 'specialistdigital-report-lead'
                        : '',
                    params: {
                      idfile: item.item.score_id || item.item.last_score,
                      idlead: item.item.lead_id,
                      accountId: item.item.client_account_id,
                      modul: moduleId,
                      isDigital: true,
                    },
                  }"
                >
                  <feather-icon
                    size="20"
                    :class="isDarkSkin ? 'text-primary' : 'text-dark'"
                    icon="FileTextIcon"
                  />
                </router-link>
              </div>
              <div style="width: 20px">
                <a
                  target="_blank"
                  class="pr-2"
                  v-if="item.item.route_file != null"
                  :href="item.item.route_file"
                >
                  <b-img
                    :src="require('@/assets/images/icons/pdf.png')"
                    width="20"
                  >
                  </b-img>
                </a>
              </div>
            </div>
          </template>

          <template #cell(round)="item">
            <div
              class="text-center text-primary"
              :class="{ 'cursor-pointer': isSpecialist }"
              style="margin-top: 5px"
            >
              <div @click="isSpecialist && openModalAddRound(item.item)">
                {{ item.item.title.split(" ")[0] }}
              </div>
            </div>
          </template>
          <template #cell(type_send)="{ item }">
            <center style="margin-top: 5px">
              <b-badge
                :variant="
                  item.type_send === 'PHONE' ? 'light-info' : 'light-primary'
                "
              >
                {{ item.type_send }}
              </b-badge>
            </center>
          </template>

          <template #cell(tu)="item">
            <div>
              {{ item.item.sumtr || "-" }}
            </div>
          </template>

          <template #cell(ex)="item">
            <div>
              {{ item.item.sumex || "-" }}
            </div>
          </template>

          <template #cell(eq)="item">
            <div>
              {{ item.item.sumeq || "-" }}
            </div>
          </template>

          <template #cell(sent_by)="item">
            <div>
              {{ item.item.sent_by }} <br />
              {{ item.item.date_send | myGlobalDay }}
            </div>
          </template>

          <template #cell(action)="{ item }">
            <div class="d-flex justify-content-around gap-1">
              <FeatherIcon
                icon="EditIcon"
                class="text-warning cursor-pointer"
                size="20"
                @click="
                  openModalEditRoundLetter(
                    item.id,
                    item.client_account_id,
                    item.account,
                    item.client_name
                  )
                "
              />

              <b-button
                size="sm"
                variant="success"
                :disabled="
                  item.sumeq > 0 || item.sumex > 0 || item.sumtr > 0
                    ? false
                    : true
                "
                @click="openModalProcessDirect(item, 0)"
              >
                Done
              </b-button>

              <feather-icon
                v-if="
                  (isSpecialist && isCeo) ||
                  isSupervisor ||
                  isChief ||
                  isAdvisor
                "
                icon="TrashIcon"
                size="20"
                class="cursor-pointer text-danger"
                variant="danger"
                @click="deleteRoundLetter(item.id)"
              />
            </div>
          </template>

          <template #cell(tracking)="item">
            <div class="text-center">
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                icon="ListIcon"
                @click="openModalTracking(item.item)"
                size="20"
              />
            </div>
          </template>
        </b-table>
        <!--:::fin table::::-->
      </filter-slot>
    </b-container>
    <modal-tracking
      v-if="modalTrackingState"
      :dataClient="dataClient"
      @hideModal="modalTrackingState = false"
    />
    <modal-process-direct
      v-if="modalProcessDirect"
      :dataClient="dataClient"
      :direct="directType"
      :validedit="validEdit"
      @hideModal="modalProcessDirect = false"
      @update="refreshTable"
    />
    <modal-edit-round-letter
      v-if="modalEditRoundLetterState"
      :idround="ncrId"
      :idaccount="clientAccountId"
      :account="accountId"
      :nameClient="nameClient"
      :direct="true"
      @updateTable="refreshTable"
      @hideModal="modalEditRoundLetterState = false"
    />
    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      @close="closeRoundModal()"
    />
  </div>
</template>
<script>
import DirectsRoundService from "@/views/specialist-digital/views/directs-round/directs-round.service.js";
import ClientOptions from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import ModalTracking from "@/views/commons/components/letters/components/modal/ModalTracking.vue";
import ModalEditRoundLetter from "@/views/specialist-digital/views/letters-round/components/modal/ModalEditRoundLetter.vue";
import ModalProcessDirect from "@/views/specialist-digital/views/directs-round/components/modals/ModalProcessDirect.vue";
import Fields from "../data/fields.data";
import Filters from "../data/filters.data";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import { mapGetters } from "vuex";

import specialistsCounter from "@/views/specialists/specialists-counter";
import analystDepartmentCounter from "@/views/specialists/sub-modules/analyst-department/analyst-department-counter";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    DirectsRoundService,
    ModalTracking,
    ModalEditRoundLetter,
    ModalProcessDirect,
    AddRoundModal,
    StatusAccount,
  },
  props: {
    statusDirectType: {
      type: String,
    },
  },
  created() {
    this.sortDirection = this.isInCompletedTab ? "desc" : "asc";
  },
  data() {
    return {
      dataClient: null,
      directType: 5,
      fields: Fields,
      filters: Filters,
      orderBy: 24,
      sortDirection: "asc",
      from: "",
      to: "",
      campo1: "",
      program: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      itemClient: {
        id: null,
        idAccount: null,
        account: null,
        clientName: null,
      },
      modalEditRoundLetterState: false,
      modalProcessDirect: false,
      modalTrackingState: false,
      modalAddRoundLetterState: false,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 25,
      },
      startPage: 0,
      status: null,
      toPage: 0,
      user: "",
      validEdit: null,
      ncrId: "",
      clientAccountId: "",
      accountId: "",
      nameClient: "",
    };
  },
  methods: {
    async myProvider(ctx) {
      const params = {
        name_text:
          this.filterPrincipal.model == "" ? null : this.filterPrincipal.model,
        status: this.isDirectCompleted,
        orderby: this.orderBy,
        order: this.sortDirection,
        userid: this.currentUser.user_id,
        roleid: this.currentUser.role_id,
        directType: this.getFilterByKey("type").model,
        typeClient: this.isModulSpecialistDigital,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        from: this.getFilterByKey("from").model,
        to: this.getFilterByKey("to").model,
        program: [11, 25].includes(this.moduleId)
          ? this.getFilterByKey("program").model
          : 3,
        advisor_id:
          this.currentUser.role_id == 8 ? this.currentUser.user_id : this.user,
      };
      const data = await DirectsRoundService.getDirectRound(params);
      this.totalRows = data.data.total;
      this.startPage = data.data.from;
      this.toPage = data.data.to;
      return data.data.data || [];
    },
    getFilterByKey(key) {
      return this.filters.find((filter) => filter.key === `${key}`);
    },
    cssGlobal(clases) {
      let classCss = {};
      clases.w ? (classCss.width = clases.w) : "";
      clases.h ? (classCss.height = clases.h) : "";
      clases.border ? (classCss.border = clases.border) : "";
      clases["border-t"] ? (classCss["border-top"] = clases["border-t"]) : "";
      clases["border-l"] ? (classCss["border-left"] = clases["border-l"]) : "";
      clases["border-b"]
        ? (classCss["border-bottom"] = clases["border-b"])
        : "";
      clases["border-r"] ? (classCss["border-right"] = clases["border-r"]) : "";
      clases["b-radius"]
        ? (classCss["border-radius"] = clases["b-radius"])
        : "";
      clases.l ? (classCss.left = clases.l) : "";
      clases.t ? (classCss.top = clases.t) : "";
      clases.position ? (classCss.position = clases.position) : "";
      clases.transform ? (classCss.transform = clases.transform) : "";
      clases.p ? (classCss.padding = clases.p) : "";
      clases["p-l"] ? (classCss["padding-left"] = clases["p-l"]) : "";
      clases["mt"] ? (classCss["margin-top"] = clases["mt"]) : "";
      clases["mr"] ? (classCss["margin-right"] = clases["mr"]) : "";
      clases.bg ? (classCss.background = clases.bg) : "";
      return classCss;
    },
    closeRoundModal() {
      this.modalAddRoundLetterState = false;
      this.$refs.directsRoundListTable.refresh();
    },
    refreshTable() {
      this.$refs.directsRoundListTable.refresh();
    },
    openModalProcessDirect(data, validEdit) {
      this.dataClient = data;
      this.validEdit = validEdit;
      this.directType = data.t_send;
      this.modalProcessDirect = true;
    },
    openModalTracking(data) {
      this.dataClient = data;
      this.modalTrackingState = true;
    },
    openModalAddRound(data) {
      this.itemClient = {
        id: data.ncr_letters_id,
        idAccount: data.client_account_id,
        account: data.account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          origin_country: data.origin_country,
        },
        isDirect: { id: data.id, title: data.title, format: data.format },
      };
      this.modalAddRoundLetterState = true;
    },
    openModalEditRoundLetter(ncr_id, client_account_id, account, name_client) {
      this.ncrId = ncr_id;
      this.clientAccountId = client_account_id;
      this.accountId = account;
      this.nameClient = name_client;
      this.modalEditRoundLetterState = true;
    },
    async deleteRoundLetter(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const params = {
            id: id,
            user_id: this.currentUser.user_id,
          };
          const response = await ClientOptions.deleteRoundLetter(params);
          if (response.status === 200) {
            specialistsCounter(); //update counter specialists
            analystDepartmentCounter();
            this.showSuccessSwal();
            this.refreshTable();
          }
        }
      } catch (error) {
        console.error("Error tring to delete a round letter");
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isInCompletedTab() {
      return this.$route.meta.typeTab === "completed";
    },
    isDirectCompleted() {
      if (this.statusDirectType === "completed") return 7;
      return 1;
    },
    filteredFields() {
      this.fields[10].visible = false;
      this.fields[11].visible = this.isInCompletedTab;
      this.fields[12].visible = !this.isInCompletedTab;
      this.fields[13].visible = this.isInCompletedTab;
      if (![11, 25].includes(this.moduleId)) {
        this.fields[12].visible = false;
        this.fields[13].visible = false;
      }
      return this.fields.filter((field) => field.visible);
    },
    validateModal() {
      return this.isInCompletedTab ? 3 : 0;
    },
    isModulSpecialistDigital() {
      return [22, 25].includes(this.$route.matched[0].meta.module) ? 2 : 1;
    },
    isSpecialist() {
      return [11, 25].includes(this.$route.matched[0].meta.module);
    },
    isAdvisor() {
      return [3].includes(this.currentUser.role_id);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    filtersC() {
      if (![28, 25].includes(this.moduleId)) {
        this.getFilterByKey("program").visible = false;
      }
      return this.filters.filter((filter) => filter.visible);
    },
  },
  watch: {
    isDirectCompleted: {
      handler(newVal, oldVal) {
        this.fields[10].visible = !this.isInCompletedTab;
        this.fields[11].visible = this.isInCompletedTab;
        this.fields[12].visible = !this.isInCompletedTab;
        this.fields[13].visible = this.isInCompletedTab;
        this.sortDirection = this.isInCompletedTab ? "desc" : "asc";
        immediate: true;
        if (newVal !== oldVal) this.refreshTable();
      },
    },
  },
};
</script>
<style>
.thTable div {
  text-align: center;
  min-height: 27px;
  display: flex;
  align-items: center;
}
.textLogos {
  height: 28px;
  margin-right: 5px;
}
.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  width: 28px;
  height: 28px;
}
</style>
