<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      modal
      size="lg"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="TRACKING"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <ModalHeader
          :dataClient="dataClient"
          :title="'TRACKING'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <b-table
          slot="table"
          ref="refTrackingTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="fields"
          :items="myProvider"
        >
          <!-- Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column: Date -->
          <template #cell(created_at)="data">
            <div style="padding-top: 5px">
              <p class="m-0">{{ data.item.created_at | myGlobalDay }}</p>
            </div>
          </template>
          <!-- Column: OBSERVATION -->
          <template #cell(obs)="data">
            <div class="text-left">
              <div class="observation" v-html="data.item.obs"></div>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
import LettersService from "../../service/letters.service";
export default {
  components: {
    ModalHeader,
  },
  props: {
    dataClient: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "user_name",
          label: "User",
        },
        {
          key: "action",
        },
        {
          key: "status_process",
          label: "Status",
        },
        {
          key: "obs",
          label: "Observation",
        },
      ],
    };
  },
  methods: {
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        let result = await LettersService.getTrackingsLetters({
          id: this.dataClient.id,
        });
        return result;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  mounted() {},
};
</script>
<style></style>
