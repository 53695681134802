<template>
    <div>
      <div class="modal-body" id="modalBody" style="padding-top: 0px;">
        <div class="col-lg-12">
          <table class="table mb-0">
            <thead
              class="bg-transparent"
            >
              <tr>
                <!-- <th class="border-0 p-0"></th> -->
                <th class="text-center border-0 p-0" v-if="clientData.sumtr">
                  <img :src="require('@/assets/images/icons/transunion-logo.png')" width="90" />
                </th>
                <th v-if="clientData.sumex" class="text-center border-0 p-0">
                  <img :src="require('@/assets/images/icons/experian-logo.png')" width="90" />
                </th>
                <th class="text-center border-0 p-1" v-if="clientData.sumeq">
                  <img :src="require('@/assets/images/icons/equifax-logo.png')" width="90" />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td v-if="clientData.sumtr" class="text-center">
                    <div :class="{'border-danger':errorTr && validator}">
                        <DragAndDrop
                            v-model="files.tr"
                            :source="'tr'"
                            :filesArray="files.tr"
                            />
                    </div>
                        <span v-if="errorTr && validator" class="text-danger"> Need file(s) to continue </span>
                </td>
                <td v-if="clientData.sumex" class="text-center">
                    <div :class="{'border-danger':errorEx && validator}">
                        <DragAndDrop
                            v-model="files.ex"
                            :source="'ex'"
                            :filesArray="files.ex"/>
                    </div>
                        <span v-if="errorEx && validator" class="text-danger"> Need file(s) to continue </span>
                </td>
                <td v-if="clientData.sumeq" class="text-center">
                    <div :class="{'border-danger':errorEq && validator}">
                        <DragAndDrop
                            v-model="files.eq"
                            :source="'eq'"
                            :filesArray="files.eq"
                        />
                    </div>
                        <span v-if="errorEq && validator" class="text-danger"> Need file(s) to continue </span>
                </td>
              </tr>
            </tbody>
          </table>

          <b-row class="mt-1 text-center">
            <b-col class="text-center">
              <h6 v-if="!isThereBureaus" class="text-danger mb-1">Please, add some bureaus before processing this round.</h6>

                <b-button variant="success" @click="processData">
                    Process
                </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

</template>

<script>
import DirectRoundsServices from "@/views/specialist-digital/views/directs-round/directs-round.service";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue"
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components:{
    DragAndDrop
  },
  props: {
    clientData: {
      type: Object,
    },
    validEdit: {
      type: Number,
    },
  },
  data() {
    return {
      validedit: this.validEdit,
      case_number: {
        round_id: this.clientData.id,
        st_tu: null,
        st_exp: null,
        st_eq: null,
        cn_tu: null,
        cn_exp: null,
        cn_eq: null,
        description: null,
      },
      files:{
        tr: [],
        ex:[],
        eq:[],
      },
      error:{
        ex: false,
        eq: false,
        tr: false
      },
      validator: false,
      formData:null,
    };
  },
  created() {
    this.getDirectComplete();
  },
  methods: {
    async setDirectBureau() {
      const result = await this.showConfirmSwal();
      if(!result.isConfirmed) return;
      try {
        this.addPreloader();
        const setBureau = await DirectRoundsServices.setDataDirect( this.case_number );
        if( setBureau.status == 200 ) {
          this.showSuccessSwal();
          this.updateTable();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error in setDirectBureau", error);
      }
    },
    async setDirectComplete() {
      const validation = await this.$refs.form.validate();
      if(!validation) return;
      const result = await this.showConfirmSwal();
      if(!result.isConfirmed) return;
      try {
        this.addPreloader();
        const setBureau = await DirectRoundsServices.setDataDirect( this.case_number );
        if( setBureau.status == 200 ) {
          const processLetters = await DirectRoundsServices.processRoundLetters({
            id: this.clientData.id,
            user: this.currentUser.user_id,
            type: 7,
            obs: this.case_number["description"],
          })
          if( processLetters.status == 200 ) {
            this.UPDATE_SIDEBAR_DIRECT_COUNTER({
              routeName: "specialistdigital-directs-round",
              tag: this.pendingDirectRounds > 99 ? "+99" : this.pendingDirectRounds - 1
            });
            this.UPDATE_TAB_DIRECT_COUNTER({
              pending: this.pendingDirectRounds - 1
            });
            this.showSuccessSwal();
            this.updateTable();
          }
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error in setDirectBureau", error);
      }
    },
    async getDirectComplete() {
      try {
        this.addPreloader();
        const response = await DirectRoundsServices.getDataDirect({ idround: this.clientData.id, });
        if( response.status == 200 ) {
          if( response.data.length > 0 ) {
            this.case_number["description"] = response.data[0].observation;
            this.case_number["cn_tu"] = response.data[0].case_number_tu;
            this.case_number["cn_exp"] = response.data[0].case_number_exp;
            this.case_number["cn_eq"] = response.data[0].case_number_eq;
          }
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error("Error in getDirectComplete", error);
      }
    },
    updateTable() {
      this.$emit("update");
      // this.$emit("close");
    },
    close(){
      this.$emit("close");
    },
    fillFormData(){
      this.validator = true

        if(this.errorTr || this.errorEq || this.errorEx)return

        this.formData = new FormData();

        this.files.tr.forEach(file => {
            this.formData.append("filesTr[]", file, file.name);
        });
        this.files.ex.forEach(file => {
            this.formData.append("filesEx[]", file, file.name);
        });
        this.files.eq.forEach(file => {
            this.formData.append("filesEq[]", file, file.name);
        });

        this.formData.append("user_id", this.currentUser.user_id);

        this.formData.append("round_id", this.clientData.id);
    },
    async processData(){

        this.addPreloader()

        this.fillFormData()

      const {status,error} = await DirectRoundsServices.saveFilesDirectDispute(this.formData)

      if(status === 200){
          const {status} = await DirectRoundsServices.processRoundLetters({
              id: this.clientData.id,
              user:this.currentUser.user_id,
              type:7,
              obs:'Process Online',
          })

        this.removePreloader()

          if(status === 200){
            this.updateTable()
            this.showSuccessSwal()
          }
          else
              this.showErrorSwal()
        }else{
            this.showErrorSwal(error)
        }

    },
    ...mapMutations({
      UPDATE_SIDEBAR_DIRECT_COUNTER: 'SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY'
    }),
    ...mapActions({
      UPDATE_TAB_DIRECT_COUNTER: 'SpecialistsDigitalDirectRoundsStore/A_SET_COUNTER_PENDING_DIRECT_ROUNDS'
    })
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      pendingDirectRounds: 'SpecialistsDigitalDirectRoundsStore/G_COUNTER_PENDING_DIRECT_ROUNDS',
    }),
    isThereBureaus() { // Checks if some bureaus is added to this round
      return !!this.clientData.sumtr || !!this.clientData.sumex || !!this.clientData.sumeq;
    },
    errorTr(){ return this.files.tr.length === 0 },
    errorEq(){ return this.files.eq.length === 0 },
    errorEx(){ return this.files.ex.length === 0 }
  }
};
</script>

<style>
</style>
