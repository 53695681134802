<template>
  <div>
    <b-modal
      v-model="modalProcessDirect"
      :title="`${ dataClient.client_name } (${ direct == 5 ? 'Online' : 'Phone' })`"
      :size="direct == 5 ? 'xl' : 'lg'"
      header-class="b-vue-modal-header "
      hide-footer
      scrollable
      modal-class="top-modal"
      no-close-on-esc
      @hidden="closeModal"
    >
      <modal-process-phone
        v-if="direct != 5"
        :clientData="dataClient"
        :validEdit="validedit"
        @update="updateTable"
        @close="closeModal"
      />
      <ModalProcessOnline
        v-else
        :clientData="dataClient"
        :validEdit="validedit"
        @update="updateTable"
        @close="closeModal"
        />

    </b-modal>
  </div>
</template>

<script>
import ModalProcessPhone from "@/views/specialist-digital/views/directs-round/components/modals/ModalProcessPhone.vue"
import ModalProcessOnline from "@/views/specialist-digital/views/directs-round/components/modals/ModalProcessOnline.vue"
export default {
  components: {
    ModalProcessPhone,
    ModalProcessOnline
  },
  props: {
    dataClient: {
      type: Object,
      required: true,
    },
    direct: {
      type: Number,
      required: true,
    },
    validedit: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      modalProcessDirect: true,
    }
  },
  methods: {
    closeModal() {
      this.modalProcessDirect = false;
      this.$emit("hideModal", 1);
    },
    updateTable() {
      this.$emit("update");
      this.$emit("hideModal", 2);
    }
  }
}
</script>
