export default [
  {
    key: "client_name",
    label: "Client",
    thClass: "pt-1",
    sortable: false,
    visible: true,
    thStyle: { width: "250px" },
  },
  {
    key: "round",
    label: "Round",
    thClass: "pt-1 text-center",
    sortable: false,
    visible: true,
  },
  {
    key: 'type_send',
    label: "Type",
    thClass: "pt-1 text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "tu",
    label: "TU",
    thClass: "text-center pt-1",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "ex",
    label: "EX",
    thClass: "text-center pt-1",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "eq",
    label: "EQ",
    thClass: "text-center pt-1",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "cr_date",
    label: "Date of CR",
    thClass: "pt-1",
    sortable: false,
    visible: true,
  },
  {
    key: "cr",
    label: "CR",
    thClass: "text-center pt-1",
    sortable: false,
    visible: true,
  },
  {
    key: "program",
    label: "Program",
    thClass: "pt-1 text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "created_by",
    label: "Created By",
    thClass: "pt-1",
    sortable: false,
    visible: true,
  },
  {
    key: "advisor_name",
    label: "Requested by",
    thClass: "pt-1",
    sortable: false,
    visible: true,
  },
  {
    key: "sent_by",
    label: "Completed by",
    thClass: "pt-1",
    visible: true,
  },
  {
    key: "action",
    label: "ACTION",
    thClass: "text-center pt-1",
    sortable: false,
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    thClass: "text-center pt-1",
    visible: true,
  },
];
