export default [
  {
    key: "from",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: true,
  },
  {
    key: "to",
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: true,
  },
  {
    key: "program",
    type: "select",
    label: "Program",
    margin: true,
    showLabel: true,
    options: [
      { value: 0, label: "All" },
      { value: 2, label: "Boost Credit" },
      { value: 3, label: "Credit Expert" },
      { value: 4, label: "Debt Solution" },
    ],
    model: "",
    reduce: "value",
    selectText: "label",
    cols: 12,
    md: 2,
    visible: true,
  },
  {
    key: "type",
    type: "select",
    label: "Type",
    margin: true,
    showLabel: true,
    options: [
      { value: 0, label: "All" },
      { value: 4, label: "PHONE" },
      { value: 5, label: "ONLINE" },
    ],
    model: "",
    reduce: "value",
    selectText: "label",
    cols: 12,
    md: 2,
    visible: true,
  },
];
